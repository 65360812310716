import styled, { css } from 'styled-components';

import { HeadingSmall } from 'components/UI/Headings';

import { easeOutTiming, fadeIn, fadeOut } from 'styles/animation';
import { media } from 'styles/mixins';

const Container = styled.div`
  max-width: 41.875rem;
`;

const QuestionBlock = styled.div`
  overflow: hidden;

  ${media.mobile`
    padding-bottom: var(--gap-mobile);
  `}

  &:not(:last-of-type) {
    border-bottom: var(--border) solid var(--border-color-10);
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    ${media.mobile`
      margin-bottom: var(--gap-mobile);
    `}
  }

  ${HeadingSmall} {
    margin-bottom: 0;
  }

  ${({ isFirstOpened }) =>
    isFirstOpened &&
    css`
      &:first-of-type p {
        margin-top: 0.5rem;
        max-height: 12.5rem;
      }
    `}

  p {
    max-height: 0;
    visibility: hidden;
    padding-right: 6.875rem;
    animation: ${fadeOut} ${easeOutTiming} forwards;

    ${({ isOpened }) =>
      isOpened &&
      css`
        margin-top: 0.5rem;
        max-height: 12.5rem;
        visibility: visible;
        animation: ${fadeIn} ${easeOutTiming} forwards;
      `}

    ${media.mobile`
      padding-right: 0;
    `}
  }
`;

const Question = styled.div`
  grid-template-columns: 1fr auto;
  column-gap: 5.625rem;
  align-items: center;
  cursor: pointer;
  display: grid;
`;

export { Container, QuestionBlock, Question };
