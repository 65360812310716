import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import { StructuredText } from 'react-datocms';

import ChevronUp from 'assets/chevron-up.svg';
import ChevronDown from 'assets/chevron-down.svg';

import { PageWrapper } from 'components/Layout/PageWrapper';
import {
  HorizontalContainer,
  QuestionsContainer,
} from 'components/UI/Containers';
import { HeadingBig, HeadingSmall } from 'components/UI/Headings';

import { QuestionBlock, Question } from './styles';

const FaqsPageTemplate = ({ data, pageContext }) => {
  const { datoCmsFaqsPage: pageData } = data;
  const { title, seo, faqList } = pageData;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <div>
            <QuestionsContainer>
              {faqList.map(({ id, question, answer }, i) => {
                const [isFirstOpen, setIsFirstOpen] = useState(true);
                const [isAnswerOpen, setIsAnswerOpen] = useState(false);

                useEffect(() => {
                  if (i === 0) setIsAnswerOpen(true);
                }, []);

                const handleClick = () => {
                  setIsFirstOpen(!isFirstOpen);
                  setIsAnswerOpen((current) => !current);
                };

                return (
                  <QuestionBlock
                    key={id}
                    isOpened={isAnswerOpen}
                    isFirstOpened={isFirstOpen}
                  >
                    <Question onClick={() => handleClick()}>
                      <HeadingSmall as="h3">{question}</HeadingSmall>
                      {isAnswerOpen ? <ChevronDown /> : <ChevronUp />}
                    </Question>
                    <StructuredText data={answer?.value} />
                  </QuestionBlock>
                );
              })}
            </QuestionsContainer>

            {/* TODO: paste animation below */}
            {/* <div></div> */}
          </div>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default FaqsPageTemplate;

export const query = graphql`
  query FaqsPageQuery($locale: String!) {
    datoCmsFaqsPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      faqList {
        id
        question
        answer {
          value
        }
      }
    }
  }
`;
